import { BaseService, Service } from "/@/cool";
@Service("imagePreview")
class imagePreview extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}

		this.permission["shootPreview"] = prefix + "shootPreview";
		this.permission["checkViewAllType"] = prefix + "checkViewAllType";
		this.permission["all"] = prefix + "all";
		this.permission["shakeCheck"] = prefix + "shakeCheck";
	}

	shootPreview(data: any) {
		return this.request({
			url: "/shootPreview",
			method: "POST",
			data
		});
	}

	checkViewAllType(data: any) {
		return this.request({
			url: "/checkViewAllType",
			method: "POST",
			data
		});
	}
	all(data: any) {
		return this.request({
			url: "/all",
			method: "POST",
			data
		});
	}
	// 审核照片
	shakeCheck(data: any) {
		return this.request({
			url: "/shakeCheck",
			method: "POST",
			data
		});
	}
}
export default imagePreview;
